<template>
  <div v-if="store.contentData" class="overallpage">
    <h2>Widerruf</h2>
    <h4>{{getContentData('widerruf').headline}}</h4>
    <span v-html="getContentData('widerruf').pageContent"></span>
    <h2>Widerruf Digital</h2>
    <h4>{{getContentData('widerruf-digital').headline}}</h4>
    <span v-html="getContentData('widerruf-digital').pageContent"></span>
  </div>
</template>

<script>
import store from '@/store/store.js';

export default {
  name: 'Delivery',
  components: {
  },
  data() {
    return {
      store,
    };
  },
  methods: {
    getContentData: function (dataHandle) {
      var contentModel = null;
      for(var i = 0; i < this.store.contentData.length; i++){
        if(this.store.contentData[i].pageHandle == dataHandle){
          contentModel = this.store.contentData[i];
        }
      }
      return contentModel;
    },
  }
}
</script>

<style lang="scss">
  
</style>
